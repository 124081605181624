<template>
  <router-view></router-view>
</template>

<script>

</script>

<style lang="scss" scoped>

</style>